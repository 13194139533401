'use strict'

const {constants} = require('@wix/santa-core-utils')

const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')

const cleanRef = ref => dataUtils.stripHashIfExists(ref)

function moveRotationFromLayoutToTransformation(layout, pageData, getTransformationsUniqueId) {
    const rotationInDegrees = _.get(layout, ['componentLayout', 'rotationInDegrees'])
    pageData.layout_data[layout.id] = _.omit(layout, ['componentLayout.rotationInDegrees'])

    const transformationId = getTransformationsUniqueId()
    const transformation = dataUtils.transformations.createTransformationWithRotate(transformationId, rotationInDegrees)
    pageData.transformations_data = {
        ...pageData.transformations_data,
        [transformationId]: transformation
    }
    return transformationId
}

function moveRotationToTransformations(comps, pageData, getTransformationsUniqueId) {
    const layoutData = pageData.layout_data
    _.forEach(comps, comp => {
        if (comp.layoutQuery) {
            const layoutRefArray = layoutData[cleanRef(comp.layoutQuery)]
            const transformationsRefs = []
            _.forEach(layoutRefArray.values, value => {
                const layout = layoutData[cleanRef(value)]
                if (layout.componentLayout && layout.componentLayout.rotationInDegrees) {
                    const transformationId = moveRotationFromLayoutToTransformation(layout, pageData, getTransformationsUniqueId)
                    transformationsRefs.push(`#${transformationId}`)
                } else if (dataUtils.variantRelation.isVariantRelation(layout) && layoutData[cleanRef(layout.to)]) {
                    const innerLayout = layoutData[cleanRef(layout.to)]
                    if (innerLayout.componentLayout && innerLayout.componentLayout.rotationInDegrees) {
                        const transformationId = moveRotationFromLayoutToTransformation(innerLayout, pageData, getTransformationsUniqueId)
                        //create transformations variant relation
                        const transformationVariantRelationId = getTransformationsUniqueId()
                        const transformationVariantRelation = dataUtils.variantRelation.create({
                            id: transformationVariantRelationId,
                            variants: layout.variants,
                            from: layout.from,
                            scopedDataId: transformationId
                        })
                        pageData.transformations_data = pageData.transformations_data = {
                            ...pageData.transformations_data,
                            [transformationVariantRelationId]: transformationVariantRelation
                        }
                        transformationsRefs.push(`#${transformationVariantRelationId}`)
                    }
                }
            })
            if (transformationsRefs.length) {
                const transformationsRefArrayId = getTransformationsUniqueId()
                const transformationsRefArray = {
                    id: transformationsRefArrayId,
                    ...dataUtils.refArray.create(transformationsRefs)
                }
                pageData.transformations_data = {
                    ...pageData.transformations_data,
                    [transformationsRefArrayId]: transformationsRefArray
                }
                comp.transformationQuery = transformationsRefArrayId
            }
        }
        if (comp.components) {
            moveRotationToTransformations(comp.components, pageData, getTransformationsUniqueId)
        }
        if (comp.children) {
            moveRotationToTransformations(comp.children, pageData, getTransformationsUniqueId)
        }
        if (comp.mobileComponents) {
            moveRotationToTransformations(comp.mobileComponents, pageData, getTransformationsUniqueId)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/moveRotationToTransformation
 * @type {{exec: function}}
 */
module.exports = {
    name: 'moveRotationToTransformationFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        const {dataFixerUtils} = magicObject
        const shouldMigrate = pageJson.data.layout_data
        if (shouldMigrate) {
            const structureData = pageJson.structure
            const pageData = pageJson.data
            const getTransformationsUniqueId = () => dataFixerUtils.uniqueIdGenerator.getUniqueId(constants.DATA_TYPES.transformations, '_')

            if (!structureData) {
                return
            }
            if (structureData.components) {
                moveRotationToTransformations(structureData.components, pageData, getTransformationsUniqueId)
            }
            if (structureData.children) {
                moveRotationToTransformations(structureData.children, pageData, getTransformationsUniqueId)
            }
            if (structureData.mobileComponents) {
                moveRotationToTransformations(structureData.mobileComponents, pageData, getTransformationsUniqueId)
            }
        }
    }
}
