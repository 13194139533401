'use strict'

const _ = require('lodash')

const apps = {
    faq: {
        filterAppPart: {
            appPartName: 'f2c4fc13-e24d-4e99-aadf-4cff71092b88',
            viewName: 'ExpandQuestions'
        },
        filterAppLogicCustomization: {
            type: 'AppPartCustomization',
            forType: 'Category',
            view: 'ToggleMobile',
            key: 'comp.initialState',
            fieldId: 'toggle',
            format: 'Mobile'
        },
        modify(customization) {
            return _.assign(customization, {
                fieldId: 'vars',
                key: 'initialState'
            })
        }
    }
}

function exec(pageJson) {
    _(apps)
        .values()
        .forEach(app => {
            _(pageJson.data.document_data)
                .filter(app.filterAppPart)
                .forEach(part => {
                    part.appLogicCustomizations = _(part.appLogicCustomizations)
                        .map(customization => {
                            const shouldModify = _.find([customization], app.filterAppLogicCustomization)
                            return shouldModify ? app.modify(customization) : customization
                        })
                        .compact()
                        .value()
                })
        })
}

module.exports = {
    name: 'appPartCustomizationsFixer',
    version: 1,
    exec
}
