'use strict'

const _ = require('lodash')

function fixPinterestUrl(dataNode) {
    const url = 'www.pinterest.com/'
    dataNode.urlChoice = _.includes(dataNode.urlChoice, 'pinterest.com') ? dataNode.urlChoice : url + dataNode.urlChoice
}

/**
 * @exports utils/dataFixer/plugins/pinterestFollowFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'pinterestFollowFixer',
    version: 1,
    exec(pageJson) {
        return _(pageJson.data.document_data).pickBy({type: 'PinterestFollow'}).mapValues(fixPinterestUrl).value()
    }
}
