'use strict'

const _ = require('lodash')
const {stripHashIfExists} = require('../helpers/dataUtils')

function addNewMediaItem(id, item, data) {
    data[id] = _.assign({id}, _.omit(item, 'id'))
}

function getNewId(prefix, type, refName) {
    return `${prefix}_${type}_${refName}`
}

/**
 *
 * @param mediaParent {{BackgroundMediaDataSchema}|{WixVideoDataSchema}} the media parent
 * @param ref {'mediaRef'|'imageOverlay'|'posterImageRef'} the reference attribute, one of: backgroundMedia: mediaRef
 * @param type
 * @param pageId
 * @param pageDocumentData
 */
function duplicateAndReferenceMediaItem(mediaParent, ref, type, pageId, pageDocumentData) {
    if (mediaParent[ref].indexOf(`#${pageId}`) === 0) {
        return
    }
    const media = _.cloneDeep(pageDocumentData[stripHashIfExists(mediaParent[ref])])
    const newId = getNewId(pageId, type, ref)
    addNewMediaItem(newId, media, pageDocumentData)
    mediaParent[ref] = `#${newId}`
}

/**
 *
 * @param device {string} 'mobile'|'desktop'
 * @param pageData
 * @param pageDocumentData
 */
function setBackgroundMediaRefPerDevice(device, pageData, pageDocumentData) {
    const bgRef = stripHashIfExists(pageData.pageBackgrounds[device].ref)
    const bgItem = pageDocumentData[bgRef]
    let mediaItem
    //we might not find the media data on master page
    if (bgItem && bgItem.mediaRef) {
        mediaItem = pageDocumentData[stripHashIfExists(bgItem.mediaRef)]

        if (mediaItem.type === 'WixVideo') {
            duplicateAndReferenceMediaItem(mediaItem, 'posterImageRef', device, pageData.id, pageDocumentData)
        }
        if (bgItem.imageOverlay) {
            duplicateAndReferenceMediaItem(bgItem, 'imageOverlay', device, pageData.id, pageDocumentData)
        }
        duplicateAndReferenceMediaItem(bgItem, 'mediaRef', device, pageData.id, pageDocumentData)
    }
}

const arePageBackgroundRefsValid = pageData => _.every(['mobile', 'desktop'].map(device => !!_.get(pageData, ['pageBackgrounds', device, 'ref'])))

/**
 * For each page background device, check if mediaRef is unique.
 * @param pageData
 * @param {[{data:*,type:'desktop'|'mobile'}]} pageDocumentData of items that should be fixed
 */
function duplicatedBackgroundMediaItems(pageData, pageDocumentData) {
    if (arePageBackgroundRefsValid(pageData)) {
        ;['mobile', 'desktop'].forEach(device => setBackgroundMediaRefPerDevice(device, pageData, pageDocumentData))
    }
}

/**
 * @exports utils/dataFixer/plugins/backgroundMediaRefDuplicationFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'backgroundMediaRefDuplicationFixer',
    version: 1,
    exec(pageJson) {
        if (!pageJson.structure) {
            return
        }
        const documentData = pageJson.data.document_data
        const pageDataItems = _.filter(documentData, item => item.type === 'Page' || item.type === 'AppPage')

        _.forEach(pageDataItems, pageData => {
            duplicatedBackgroundMediaItems(pageData, documentData)
        })
    }
}
