'use strict'

const _ = require('lodash')

function fixTinyMenuLayout(components) {
    const tinyMenuComponent = _.find(components, {id: 'TINY_MENU'})
    if (tinyMenuComponent) {
        if (tinyMenuComponent.layout.fixedPosition && tinyMenuComponent.layout.x > 320) {
            tinyMenuComponent.layout.x = 250
        }
        return true
    }
    return _.some(components, component => (_.isEmpty(component.components) ? false : fixTinyMenuLayout(component.components)))
}

/**
 * @exports utils/dataFixer/plugins/tinyMenuLayoutFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'tinyMenuLayoutFixer',
    version: 1,
    exec(pageJson) {
        const isMasterPage = _.get(pageJson, ['structure', 'id']) === 'masterPage'
        if (!isMasterPage) {
            return
        }
        fixTinyMenuLayout(pageJson.structure.mobileComponents)
    }
}
