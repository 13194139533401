'use strict'

const _ = require('lodash')

const ANCHOR_TYPES = {
    BOTTOM_BOTTOM: 'BOTTOM_BOTTOM',
    BOTTOM_TOP: 'BOTTOM_TOP'
}

function getCompBottom(comp) {
    return comp && comp.layout && comp.layout.y + comp.layout.height
}

function getCompAnchors(comp) {
    return (comp.layout && comp.layout.anchors) || []
}

function isPushingDownComponentThatsAbove(anchor, fromComp, toComp) {
    let anchorBottom
    switch (anchor.type) {
        case ANCHOR_TYPES.BOTTOM_BOTTOM:
            anchorBottom = getCompBottom(toComp)
            break
        case ANCHOR_TYPES.BOTTOM_TOP:
            anchorBottom = _.get(toComp, 'layout.y')
            break
        default:
            return false
    }

    if (_.isUndefined(anchorBottom)) {
        return false
    }

    return anchorBottom - getCompBottom(fromComp) < 0
}

function isBottomBottomOrBottomTopAnchor(anchor) {
    return anchor.type === ANCHOR_TYPES.BOTTOM_BOTTOM || anchor.type === ANCHOR_TYPES.BOTTOM_TOP
}

function doesBTBBCycleExist(cycleCandidateComp, currCompInAnchorPath, siblingsMap, visitedMap) {
    if (visitedMap[currCompInAnchorPath.id]) {
        return false
    }
    visitedMap[currCompInAnchorPath.id] = true

    let anchors = getCompAnchors(currCompInAnchorPath)
    anchors = _.filter(anchors, isBottomBottomOrBottomTopAnchor)

    return _.some(
        anchors,
        anchor =>
            anchor.targetComponent === cycleCandidateComp.id ||
            doesBTBBCycleExist(cycleCandidateComp, siblingsMap[anchor.targetComponent], siblingsMap, visitedMap)
    )
}

function fixBottomTopBottomBottomCycles(children) {
    if (_.isEmpty(children)) {
        return
    }

    const siblingsMap = _.keyBy(children, 'id')
    _.forEach(children, component => {
        const anchors = getCompAnchors(component)
        _.remove(
            anchors,
            anchor =>
                isBottomBottomOrBottomTopAnchor(anchor) &&
                isPushingDownComponentThatsAbove(anchor, component, siblingsMap[anchor.targetComponent]) &&
                doesBTBBCycleExist(component, siblingsMap[anchor.targetComponent], siblingsMap, {})
        )

        fixBottomTopBottomBottomCycles(component.components)
    })
}

module.exports = {
    fixBottomTopBottomBottomCycles
}
