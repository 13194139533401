'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

module.exports = {
    name: 'blogCustomFeedPostsPerPageCustomizationFixer',
    version: 1,
    exec(pageJson) {
        _(pageJson.data.document_data)
            .filter({
                type: 'AppPart',
                appPartName: coreUtils.blogAppPartNames.CUSTOM_FEED
            })
            .forEach(compData => {
                _(compData.appLogicCustomizations)
                    .filter(customizationMatches)
                    .forEach(customization => {
                        _.assign(customization, {
                            fieldId: 'vars',
                            format: '*',
                            key: 'itemsPerPage'
                        })
                    })
            })
    }
}

function customizationMatches(customization) {
    return (
        customization.forType === 'Array' &&
        customization.key === 'comp.itemsPerPage' &&
        _.includes(['', '*'], customization.format) &&
        _.includes(['paginatedlist', 'columnBlog'], customization.fieldId) &&
        _.includes(['MediaTop', 'MediaBottom', 'MediaLeft', 'MediaRight', 'MediaZigzag', 'Masonry'], customization.view)
    )
}
