'use strict'
const _ = require('lodash')
const {vectorImageConversionUtils} = require('@wix/santa-core-utils')
const themeDataUtils = require('../helpers/themeDataUtils')
const {stripHashIfExists} = require('../helpers/dataUtils')

const popupCloseButtonClassName = 'wysiwyg.viewer.components.PopupCloseIconButton'
const svgShapeClassName = 'wysiwyg.viewer.components.svgshape.SvgShape'
const compsToConvert = {
    [svgShapeClassName]: vectorImageConversionUtils.vectorImageDefaults
}
const repeaterCompType = 'wysiwyg.viewer.components.Repeater'

const {convertStyle} = vectorImageConversionUtils
const {convertData} = vectorImageConversionUtils
const {convertProperties} = vectorImageConversionUtils
const {convertStructure} = vectorImageConversionUtils

function fixStyleItems(styles) {
    themeDataUtils.forEachStyle(styles, style => {
        if (_.includes(style.skin, 'svgshape') && style.componentClassName !== popupCloseButtonClassName) {
            const compDef = compsToConvert[svgShapeClassName]
            convertStyle(style, compDef)
        }
    })
}

function fixShapesInRepeater(component, data, properties) {
    //get the repeater suffix names if exists
    const itemCompData = data[stripHashIfExists(_.get(component, 'dataQuery', ''))]
    fixShapeComponents(component.components, data, properties, _.get(itemCompData, 'items'))
}

function fixShapeComponents(components, data, properties, repeaterDataItemsNames) {
    _.forEach(components, component => {
        if (component.components) {
            if (component.componentType === repeaterCompType) {
                fixShapesInRepeater(component, data, properties)
                //clear repeater dataItems names array
                repeaterDataItemsNames = []
            }
            fixShapeComponents(component.components, data, properties, repeaterDataItemsNames)
        } else {
            const compDef = compsToConvert[component.componentType]
            if (compDef) {
                const svgId = component.skin
                const propsQuery = stripHashIfExists(_.get(component, 'propertyQuery', ''))
                const dataQuery = stripHashIfExists(_.get(component, 'dataQuery', ''))

                if (!properties[propsQuery]) {
                    properties[propsQuery] = {}
                }

                const compData = data[dataQuery]
                const compProps = properties[propsQuery]

                convertStructure(component, compDef)
                convertData(compData, compDef, svgId)
                convertProperties(compProps, compDef)

                //Convert modes overrides
                //eslint-disable-next-line lodash/prefer-filter
                _.forEach(_.get(component, ['modes', 'overrides']), override => {
                    if (override.propertyQuery) {
                        const overridePropsQuery = stripHashIfExists(_.get(override, 'propertyQuery', ''))
                        if (!properties[overridePropsQuery]) {
                            properties[overridePropsQuery] = {}
                        }
                        const overrideProps = properties[overridePropsQuery]
                        convertProperties(overrideProps, compDef)
                    }
                })

                //get the repeater data items
                _.forEach(repeaterDataItemsNames, repeaterItemName => {
                    const itemCompData = data[`${stripHashIfExists(_.get(component, 'dataQuery', ''))}__${repeaterItemName}`]
                    if (itemCompData) {
                        convertData(itemCompData, compDef, svgId)
                    }
                })
            }
        }
    })
}

module.exports = {
    name: 'svgShapeToVectorImageFixer',
    version: 1,
    exec(pageJson) {
        const styles = pageJson.data.theme_data
        const data = pageJson.data.document_data
        const properties = pageJson.data.component_properties
        const components = pageJson.structure.components || pageJson.structure.children
        const {mobileComponents} = pageJson.structure
        const repeaterDataItemsNames = []
        if (!_.isEmpty(styles)) {
            fixStyleItems(styles)
        }

        if (!_.isEmpty(components)) {
            fixShapeComponents(components, data, properties, repeaterDataItemsNames)
        }

        if (!_.isEmpty(mobileComponents)) {
            fixShapeComponents(mobileComponents, data, properties, repeaterDataItemsNames)
        }
    }
}
