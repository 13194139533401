'use strict'
const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

const richTextStyleId = 'txtNew'
const richTextSkin = 'wysiwyg.viewer.skins.WRichTextNewSkin'

const componentTypesToFix = {
    'wysiwyg.viewer.components.WPhoto': {
        styleId: 'wp1'
    },
    'mobile.core.components.Page': {
        styleId: 'p1'
    },
    'wysiwyg.viewer.components.Repeater': {
        style: {
            type: 'ComponentStyle',
            metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false
            },
            style: {
                properties: {
                    'alpha-bg': '0',
                    'alpha-brd': '0',
                    bg: '61,155,233,1',
                    'boxShadowToggleOn-shd': 'false',
                    brd: '#324158',
                    brw: '0px',
                    rd: '0px',
                    shd: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                },
                propertiesSource: {
                    'alpha-bg': 'value',
                    'alpha-brd': 'value',
                    bg: 'value',
                    brd: 'value',
                    brw: 'value',
                    rd: 'value',
                    shd: 'value'
                },
                groups: {}
            },
            componentClassName: 'mobile.core.components.Container',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
        }
    }
}
const shouldFixComponent = comp => _.has(componentTypesToFix, comp.componentType) && (comp.styleId === richTextStyleId || comp.skin === richTextSkin)

const getStyleId = (comp, isMobile) => `${isMobile ? 'mobile-' : ''}${comp.id.replace('comp-', 'style-')}`

function fixComponentSkinAndStyle(comp, themeData, isMobile) {
    const fixData = componentTypesToFix[comp.componentType]
    if (fixData.styleId) {
        delete comp.skin
        comp.styleId = fixData.styleId
        return
    }
    const styleId = getStyleId(comp, isMobile)
    const style = _.cloneDeep(fixData.style)
    style.id = styleId
    themeData[styleId] = style
    comp.styleId = styleId
    delete comp.skin
}

/**
 * @exports utils/dataFixer/plugins/richTextSkinDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'richTextSkinDataFixer',
    version: 1,
    exec(pageJson) {
        const desktopComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false, shouldFixComponent)
        const mobileComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true, shouldFixComponent)
        _.forEach(desktopComps, comp => fixComponentSkinAndStyle(comp, pageJson.data.theme_data, false))
        _.forEach(mobileComps, (comp, compId) => {
            // don't fix pages in both mobile and desktop
            if (desktopComps[compId] !== comp) {
                fixComponentSkinAndStyle(comp, pageJson.data.theme_data, true)
            }
        })
    }
}
