'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

const fixer = {
    name: 'appPartMobileFeedMediaDateHiddenFixer',
    version: 1,
    exec(pageJson) {
        _.forEach(pageJson.data.document_data, compData => {
            if (compData.appPartName && compData.appPartName === coreUtils.blogAppPartNames.FEED) {
                fixer.fixMobileDateCustomization(compData)
            }
        })
    },

    fixMobileDateCustomization(appPart) {
        _.forEach(appPart.appLogicCustomizations, customization => {
            if (
                //eslint-disable-next-line lodash/prefer-matches
                customization.fieldId === 'date' &&
                customization.format === 'Mobile' &&
                customization.forType === 'Post' &&
                customization.key === 'comp.hidden'
            ) {
                customization.fieldId = 'dateAndAuthor'
            }
        })
    }
}

module.exports = fixer
