'use strict'

module.exports = {
    'wysiwyg.viewer.components.MatrixGallery': {propType: 'MatrixGalleryProperties'},
    'wysiwyg.viewer.components.PaginatedGridGallery': {propType: 'PaginatedGridGalleryProperties'},
    'wysiwyg.viewer.components.SliderGallery': {propType: 'SliderGalleryProperties'},
    'wysiwyg.viewer.components.SlideShowGallery': {propType: 'SlideShowGalleryProperties'},
    'tpa.viewer.components.Masonry': {propType: 'MasonryProperties'},
    'tpa.viewer.components.Accordion': {propType: 'AccordionProperties'},
    'tpa.viewer.components.Impress': {propType: 'ImpressProperties'},
    'tpa.viewer.components.Freestyle': {propType: 'FreestyleProperties'},
    'tpa.viewer.components.Collage': {propType: 'CollageProperties'},
    'tpa.viewer.components.Honeycomb': {propType: 'HoneycombProperties'},
    'tpa.viewer.components.StripShowcase': {propType: 'StripShowcaseProperties'},
    'tpa.viewer.components.StripSlideshow': {propType: 'StripSlideshowProperties'},
    'tpa.viewer.components.Thumbnails': {propType: 'ThumbnailsProperties'},
    'wysiwyg.viewer.components.tpapps.TPA3DGallery': {propType: 'SlideShowGalleryProperties'},
    'wysiwyg.viewer.components.tpapps.TPA3DCarousel': {propType: 'SlideShowGalleryProperties'}
}
