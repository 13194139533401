'use strict'

function createBehaviorsDataItem(behaviors, uniqueIdGenerator) {
    const dataItem = {
        id: uniqueIdGenerator.getUniqueId('behavior', '-'),
        type: 'ObsoleteBehaviorsList',
        items: behaviors
    }
    return dataItem
}

module.exports = {
    createBehaviorsDataItem
}
