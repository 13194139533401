'use strict'

const _ = require('lodash')

const TPA_GLUED_WIDGET_COMP_TYPE = 'wysiwyg.viewer.components.tpapps.TPAGluedWidget'

const MASTER_PAGE_ID = 'masterPage'

function fixComponentsLayoutFixedPosition(pageJson) {
    //eslint-disable-next-line lodash/prefer-filter
    _.forEach(pageJson.structure.children, compStructure => {
        if (compStructure.componentType === TPA_GLUED_WIDGET_COMP_TYPE) {
            compStructure.layout.fixedPosition = true
        }
    })
}

module.exports = {
    /*
     * Add a 'fixedPosition: true' property to glued widgets' layout
     */
    name: 'tpaGluedWidgetDataFixer',
    version: 1,
    exec(pageJson) {
        if (_.get(pageJson, 'structure.id') === MASTER_PAGE_ID) {
            fixComponentsLayoutFixedPosition(pageJson)
        }
    }
}
