'use strict'

const _ = require('lodash')
const {CompsMigrationHelper} = require('../helpers/CompsMigrationHelper')
const galleryTypes = require('../maps/galleryTypes')

//we assume that the desktop page run before the mobile comps in the helper
function compMigrationFunction(pageJson, cache, isMobile, comp) {
    if (!isMobile) {
        cache[comp.id] = comp
        return
    }
    const desktopComp = cache[comp.id] || {}
    if (!desktopComp.componentType && desktopComp.componentType === comp.componentType) {
        return
    }
    const correctCompType = desktopComp.componentType
    comp.componentType = correctCompType
    const compProperties = this.getComponentProperties(pageJson, comp)
    const correctPropType = galleryTypes[correctCompType] ? galleryTypes[correctCompType].propType : ''
    if (correctCompType && (!compProperties || compProperties.type !== correctPropType)) {
        comp.propertyQuery = desktopComp.propertyQuery
    }
}

module.exports = {
    name: 'galleriesMobileTypeFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        const map = _.mapValues(galleryTypes, () => compMigrationFunction)
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const helper = new CompsMigrationHelper(map, uniqueIdGenerator)

        helper.migratePage(pageJson)
    }
}
