'use strict'

const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')

const createDefaultSingleLayoutData = () => ({componentLayout: {}, itemLayout: {}, containerLayout: {}, type: 'SingleLayoutData'})

const migrateToSingleLayoutData = (value, layoutItem, unusedRefValues) => {
    let newValue
    if (!value) {
        newValue = createDefaultSingleLayoutData()
        newValue.id = layoutItem.id
        newValue.metaData = layoutItem.metaData
    } else {
        newValue = value
        unusedRefValues.push(layoutItem.id)
    }
    const layoutItemWithoutIdAndMetaData = _.omit(layoutItem, 'breakpoint', 'id', 'metaData')
    switch (layoutItemWithoutIdAndMetaData.type) {
        case 'FlexContainerLayout':
        case 'GridContainerLayout':
        case 'StackContainerLayout':
        case 'OrganizerContainerLayout':
            newValue.containerLayout = _.isEmpty(newValue.containerLayout) ? layoutItemWithoutIdAndMetaData : newValue.containerLayout
            break
        case 'FlexItemLayout':
        case 'GridItemLayout':
        case 'StackItemLayout':
        case 'FixedItemLayout':
        case 'OrganizerItemLayout':
            newValue.itemLayout = _.isEmpty(newValue.itemLayout) ? layoutItemWithoutIdAndMetaData : newValue.itemLayout
            break
        case 'ComponentLayout':
            newValue.componentLayout = _.isEmpty(newValue.componentLayout) ? layoutItemWithoutIdAndMetaData : newValue.componentLayout
            break
        case 'SingleLayoutData':
            newValue.containerLayout = _.isEmpty(newValue.containerLayout) ? layoutItem.containerLayout : newValue.containerLayout
            newValue.itemLayout = _.isEmpty(newValue.itemLayout) ? layoutItem.itemLayout : newValue.itemLayout
            newValue.componentLayout = _.isEmpty(newValue.componentLayout) ? layoutItem.componentLayout : newValue.componentLayout
            break
    }

    return newValue
}

const migrateNonScopedValues = (nonScopeValue, layoutItem, unusedRefValues) => migrateToSingleLayoutData(nonScopeValue, layoutItem, unusedRefValues)

const migrateScopedValues = (scopedValues, relationItem, layoutItem, breakpoint, unusedRefValues) => {
    if (scopedValues) {
        unusedRefValues.push(relationItem.id)
    }

    return migrateToSingleLayoutData(scopedValues, layoutItem, unusedRefValues)
}

const getBreakpointIdFromVariants = (dataMap, variants) => _.find(variants, variantId => dataMap.variants_data[variantId].type === 'BreakpointRange')

const migrateOldLayoutItemsAndSaveUnusedItems = (dataMap, scopesMap, currentRefValues, unusedRefValues) => {
    _.forEach(currentRefValues, layoutInRefArrayId => {
        const layoutInRefArray = dataMap.layout_data[layoutInRefArrayId]
        let breakpoint
        let layoutItemId = layoutInRefArrayId
        if (dataUtils.breakpointRelation.isBreakpointRelation(layoutInRefArray)) {
            breakpoint = dataUtils.breakpointRelation.extractBreakpointWithoutHash(layoutInRefArray)
            layoutItemId = dataUtils.breakpointRelation.extractRefWithoutHash(layoutInRefArray)
        } else if (dataUtils.variantRelation.isVariantRelation(layoutInRefArray)) {
            const variants = dataUtils.variantRelation.extractVariantsWithoutHash(layoutInRefArray)
            breakpoint = getBreakpointIdFromVariants(dataMap, variants)
            layoutItemId = dataUtils.variantRelation.extractToWithoutHash(layoutInRefArray)
        }

        const layoutItem = dataMap.layout_data[layoutItemId]
        if (breakpoint) {
            scopesMap.scoped[breakpoint] = migrateScopedValues(scopesMap.scoped[breakpoint], layoutInRefArray, layoutItem, breakpoint, unusedRefValues)
        } else {
            scopesMap.nonScoped = migrateNonScopedValues(scopesMap.nonScoped, layoutItem, unusedRefValues)
        }
    })
}

const deleteUnusedRefValues = (dataMap, refArray, currentRefValues, unusedRefValues) => {
    const newRefValues = _.difference(currentRefValues, unusedRefValues)
    dataMap.layout_data[refArray.id] = dataUtils.refArray.update(refArray, newRefValues)
    _.forEach(unusedRefValues, id => {
        delete dataMap.layout_data[id]
    })
}

const updateDataMap = (dataMap, scopesMap) => {
    const nonScopeId = _.get(scopesMap.nonScoped, 'id')
    if (nonScopeId) {
        dataMap.layout_data[nonScopeId] = scopesMap.nonScoped
    }

    _.forEach(scopesMap.scoped, scopeValue => {
        const scopeId = scopeValue.id
        dataMap.layout_data[scopeId] = scopeValue
    })
}

const fixRefArrays = (dataMap, refArrays) => {
    _.forEach(refArrays, refArray => {
        const scopesMap = {
            nonScoped: undefined,
            scoped: {}
        }

        const unusedRefValues = []
        const currentRefValues = dataUtils.refArray.extractValuesWithoutHash(refArray)

        migrateOldLayoutItemsAndSaveUnusedItems(dataMap, scopesMap, currentRefValues, unusedRefValues)
        deleteUnusedRefValues(dataMap, refArray, currentRefValues, unusedRefValues)
        updateDataMap(dataMap, scopesMap)
    })
}

/**
 * Fixes:
 * - migrate all old responsiveLayout items to SingleLayoutData
 * - aggregate up to 3 old items to 1 singleLayoutData according to the scope and update data.layout_data with the new singleLayoutData item
 * - remove the unused items from ref array
 * - remove the unused items from data.layout_data
 * */
const fixPageData = page => {
    const {data} = page
    const pageRefArrays = _.filter(data.layout_data, ['type', 'RefArray'])
    fixRefArrays(data, pageRefArrays)

    return data
}

/**
 * @exports utils/dataFixer/plugins/migrateResponsiveLayoutToSingleLayoutData
 * @type {{exec: function}}
 */
module.exports = {
    name: 'migrateResponsiveLayoutToSingleLayoutData',
    version: 1,
    exec(pageJson) {
        const pageLayoutData = pageJson.data.layout_data
        const oldLayoutData = _.filter(
            pageLayoutData,
            layout => !_.includes(['RefArray', 'SingleLayoutData', 'BreakpointRelation', 'VariantRelation'], layout.type)
        )
        if (_.isEmpty(oldLayoutData)) {
            return // don't need to run no layout data
        }

        fixPageData(pageJson)
    }
}
