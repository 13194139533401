const _ = require('lodash')
const gallerySkinsToTypes = require('../maps/gallerySkinsToTypes')

function compMigrationFunction(pageJson, cache, comp) {
    const hasComponentType = !_.isNil(comp.componentType)
    const componentTypeExistForSkin = comp.skin && gallerySkinsToTypes[comp.skin]
    if (hasComponentType || !componentTypeExistForSkin) {
        return
    }
    comp.componentType = gallerySkinsToTypes[comp.skin]
}

function migrateComps(pageJson, cache, comps) {
    if (!comps) {
        return
    }
    _.forEach(comps.slice(), comp => {
        compMigrationFunction(pageJson, cache, comp)
        migrateComps(pageJson, cache, comp.components)
    })
}

module.exports = {
    name: 'galleriesMissingComponentType',
    version: 1,
    exec(pageJson) {
        const structureData = pageJson.structure
        if (structureData) {
            const cache = {}
            const mobileComps = structureData.mobileComponents
            if (mobileComps) {
                migrateComps(pageJson, cache, mobileComps)
            }
        }
    }
}
