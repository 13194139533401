'use strict'

const _ = require('lodash')

function getSitePagesFromMasterPage(masterPage) {
    let sitePages

    if (masterPage.structure.children) {
        const pageContainer = _.find(masterPage.structure.children, {id: 'PAGES_CONTAINER'})

        if (pageContainer && !_.isEmpty(pageContainer.components)) {
            sitePages = _.find(pageContainer.components, {id: 'SITE_PAGES'})
        }
    }

    return sitePages
}

function getMobileSitePagesFromMasterPage(masterPage) {
    let mobileSitePages

    if (masterPage.structure.mobileComponents) {
        const mobilePageContainer = _.find(masterPage.structure.mobileComponents, {id: 'PAGES_CONTAINER'})
        if (mobilePageContainer && !_.isEmpty(mobilePageContainer.components)) {
            mobileSitePages = _.find(mobilePageContainer.components, {id: 'SITE_PAGES'})
        }
    }

    return mobileSitePages
}

function fixSitePagesContainsOnlyPages(sitePages) {
    if (!_.isEmpty(sitePages.components)) {
        sitePages.components = []
    }
}

function fixSitePagesTop(sitePages) {
    if (sitePages.layout) {
        sitePages.layout.y = 0
    }
}

/**
 * @exports utils/dataFixer/plugins/masterPageFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'sitePagesFixer',
    version: 1,
    exec(pageJson) {
        if (pageJson.structure && pageJson.structure.type === 'Document') {
            //masterPage
            const sitePages = getSitePagesFromMasterPage(pageJson)
            const mobileSitePages = getMobileSitePagesFromMasterPage(pageJson)

            if (sitePages) {
                fixSitePagesContainsOnlyPages(sitePages)
                fixSitePagesTop(sitePages)
            }

            if (mobileSitePages) {
                fixSitePagesContainsOnlyPages(mobileSitePages)
                fixSitePagesTop(mobileSitePages)
            }
        }

        return pageJson
    }
}
