'use strict'

const _ = require('lodash')

const GROUP_COMP_TYPE = 'wysiwyg.viewer.components.Group'
const GROUP_SKIN = 'wysiwyg.viewer.components.GroupSkin'

function fixSkin(compStructure) {
    if (compStructure.componentType === GROUP_COMP_TYPE) {
        compStructure.skin = GROUP_SKIN
    }
}

function runOnAllComps(comps) {
    _.forEach(comps, comp => {
        fixSkin(comp)
        runOnAllComps(comp.components)
    })
}

module.exports = {
    name: 'groupFixer',
    version: 1,
    exec(pageJson) {
        const structureData = pageJson.structure
        if (structureData) {
            const desktopComps = structureData.components || structureData.children
            const mobileComps = structureData.mobileComponents

            if (desktopComps) {
                runOnAllComps(desktopComps)
            }

            if (mobileComps) {
                runOnAllComps(mobileComps)
            }
        }
    }
}
